// Override the primary and secondary colors and export them to be used in react components
$primary: #282561;
:export { primaryColor: $primary; }
$secondary: #f05a2a;
:export { secondaryColor: $secondary; }

// Slider colors
$slider-color-0: #ff0055;
$slider-color-50: #ffee00;
$slider-color-100: #55ff00;

// Team status colors
$bad-status: red;
:export { badStatus: $bad-status; }
$neutral-status: orange;
:export { neutralStatus: $neutral-status; }
$good-status: #00ff00;
:export { goodStatus: $good-status; }

:export { lightRed: lighten($bad-status, 30%); }
:export { lightOrange: lighten($bad-status, 30%); }
:export { lightGreen: lighten($good-status, 30%); }

// Chart colors
$chart-area-color: #c1272d;
:export { chartAreaColor: $chart-area-color; }

// Change the range input size
$custom-range-track-height: 1rem;
$custom-range-thumb-width: 2rem;

#on-course-logo {
    margin: 1em;
    display: block;
}

#logoutButton {
    float: right;
}

#content {
    margin: 2rem 0.5rem;
}

nav {
    min-height: 55.6px;
}

.navbar .navbar-title {
    display: none;
}

.navbar .route-descr {
    float: right;
}

.navbar .route-descr .btn-info {
    display: none;
}

#content .navbar-title {
    display: block;
}

#content .route-descr .btn-info {
    margin-top: .5rem;
}

nav h1 {
    margin-bottom: 0;
    text-align: center;
}

#content h1 {
    margin-bottom: 2rem;
    text-align: center;
}

#content img {
    margin: 1em;
}

.dropdown-menu.show {
    z-index: 1021;
}

#About img {
    float: right;
}

.NotFound, .ActivitiesStatus {
    text-align: center;
}

.NotFound h2 {
    font-size: 10em;
}

#forgot-password-link {
    margin-top: .5em;
    display: block;
    float: right;
}

FontAwesomeIcon {
    display: inline;
}

.status-icon-btn {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
}

.status-icon span {
    height: 32px;
    width: 32px;
}

#overview-list .list-group-item {
    padding: 1rem 1rem 0rem;
}

#overview-list .list-group-item .status-icon {
    float: right;
}

#nextButton {
    float: right;
}

#team-question-controls {
    width: 100%;
}

button.description-toggle {
    border-radius: 2rem;
}

hr {
    border-top: 1px $primary;
}

$hr-border-color: $primary !default;
$hr-border-width: 2px !default;

@import '~bootstrap/scss/bootstrap.scss';

// Show a gradient for range inputs
.gradient {
    &::-webkit-slider-runnable-track {
        @include gradient-x-three-colors($slider-color-0, $slider-color-50, 50%, $slider-color-100);
    }
    &::-moz-range-track  {
        @include gradient-x-three-colors($slider-color-0, $slider-color-50, 50%, $slider-color-100);
    }
}

.invalid-feedback {
    font-size: $font-size-base;
    margin-bottom: 1rem;
}

.list-group-item * {
    margin: 0;
}

.list-group-item .title {
    font-size: $font-size-base * 1.2;
    margin: 0;
}

.overview-chart h2 {
    font-size: $font-size-base * 1.5;
}

nav h1.navbar-title {
    font-size: $font-size-base * 1.5;
}

#content h1.navbar-title {
    font-size: $font-size-base * 2;
}

:export { successColor: $success; }

// Scale down the question headers
#question h2 {
    font-size: $font-size-base * 1.75;
}

#question h3 {
    font-size: $font-size-base * 1.5;
}

.slider-label {
    font-size: $font-size-base * 1.3;
}

@include media-breakpoint-down(sm) { 

    // Center the app logo on small screens
    #on-course-logo {
        margin: 0.5em auto;
        width: 150px;
    }

    #logoutButton {
        float: none;
        margin-top: 0.5rem;
    }
 
    // Show navbar-title in navbar on small screens
    .navbar .navbar-title {
        display: block;
    }

    #content .navbar-title {
        display: none;
    }

    .list-group-item .title {
        padding: 0;
        margin:0 ;
    }

    // Show route description in navbar on small screens

    #content .route-descr .btn-info {
        display: none;
    }

    .navbar .route-descr .btn-info {
        display: block;
    }

}

@include media-breakpoint-down(xs) { 
    #content {
        margin: 1.5rem .5rem;
    }

    // Center images on smaller screens
    #content img {
        margin: 1em auto;
        display: block;
    }

    // Disable float of images for small screens
    #About img {
        float: none;
    }

    // Make more room by disabling labels on login screen
    label[for=password] {
        display: none;
    }

    label[for=user] {
        display: none;
    }
}

@import '~bootstrap/scss/bootstrap.scss';

.wide-popover {
    max-width: 600px;
}

// Additional styles for print

@media print {
    .popover, #team-question-controls, .btn-info, hr, nav, #print-button, header, footer, aside, form { display: none; }

    .print-page-sep {
        width: 100%;
        height: 220px;
    }

    .bubbleChart {
        padding: 0 0 50px;

        .bubble-chart-legend {
            margin: 0;
            padding: 0;
            width: 400px;
        }
    }
}
